@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

p {
  font-size: 1rem;
  margin: 0;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
}

html,
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

button {
  font-family: inherit;
}

.small {
  font-size: 0.8em;
}

@media (max-width: 1200px) {
  html {
    font-size: 12px; /* original value 16px */
  }
}

@media (max-width: 992px) {
  html {
    font-size: 10px; /* original value 15px */
  }
}

@media (max-width: 768px) {
  html {
    font-size: 10px; /* original value 14px */
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 10px; /* original value 12px */
  }
}

* {
  box-sizing: border-box;
  font-family: var(--font-family);
}

html,
body {
  font-size: 0.825rem;
  margin: 0;
  line-height: 1;
}

html {
  background-color: transparent;
}

body {
  background:var(---background-color-terciary);
  color: var(--text-primary-color);
  min-height: 100vh;
  min-width: 320px;
  padding: 0;
}

.loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.loader::before {
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  border: 16px solid var(--background-color-secondary);
  border-radius: 50%;
  border-top: 16px solid var(--buttons-color);
  content: "";
  height: 120px;
  width: 120px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.App > .loader {
  min-height: calc(100vh - 6rem);
  padding-bottom: 1.5rem;
  padding-top: 7.5rem;
}

.d-none {
  display: none !important;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
}

button {
  font: inherit;
}

blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.layout-children {
  width: 100%;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/****** MOBILE TABLET display none - START ******/
@media screen and (min-width: 1024px) {
  .lg-none {
    display: none !important;
  }
}

@media screen and (min-width: 475px) and (max-width: 1023px) {
  .md-none {
    display: none !important;
  }

  .lg-block {
    display: none !important;
  }
}

@media screen and (max-width: 474px) {
  .xs-none {
    display: none !important;
  }

  .lg-block {
    display: none !important;
  }
}

/****** MOBILE TABLET display none - END ******/

@supports (-webkit-overflow-scrolling: touch) {
  /** prevent zoom in on inputs on mobile devices **/
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

/****** MATERIAL UI  ******/
.MuiPopover-root .MuiPaper-root {
  background-color: var(--background-color-main);
  border-radius: 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  padding: 0.2rem;
}

:root {
  --background-color-main: #333;
  --background-color-secondary: #222;
  --background-color-terciary: #000000;
  --text-primary-color: #fff;
  --text-secondary-color: #e4e2e5;
  --text-terciary-color: #ccc9ce;
  --color-warning: #f24141;
  --color-success: #1bda1b;
  --color-active: #7781e9;
  --buttons-color: #fff;
  --buttons-border-radius: 2px;
}

